import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button/Button';
import { StaticImage } from 'gatsby-plugin-image';
import LearnMoreLink from 'components/atoms/LearnMoreLink/LearnMoreLink';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';
import HeroShaped from 'components/organisms/HeroShaped/HeroShaped';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .hero-shaped': {
      borderBottom: 0,
    },
    '& .hero-shaped__wrapper': {
      [theme.breakpoints.up('md')]: {
        minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
      },
    },
  },
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      maxWidth: 500,
      margin: `0 auto`,
    },
  },
  image: {
    objectFit: 'cover',
  },
  label: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}));

const NotFoundCover = () => {
  const classes = useStyles();

  const handleClick = () => {
    window.history.back();
  };

  return (
    <div className={classes.root}>
      <HeroShaped
        leftSide={
          <div className={classes.formContainer}>
            <SectionHeader
              label="404"
              title="It's not your fault!"
              subtitle={
                <span>
                  The page you are looking for does not seem to be here. If you
                  are looking for something in particular please
                  <LearnMoreLink
                    title="let us know"
                    href="/contact-us"
                    typographyProps={{ variant: 'h6' }}
                  />
                </span>
              }
              titleProps={{
                variant: 'h3',
              }}
              labelProps={{
                color: 'secondary',
                className: classes.label,
                variant: 'h5',
              }}
              ctaGroup={[
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                >
                  Go Back
                </Button>,
              ]}
              disableGutter
            />
          </div>
        }
        rightSide={
          <StaticImage
            src="images/fault.jpg"
            alt="404 not found, but it's not your fault!"
            className={classes.coverImage}
            placeholder="blurred"
            objectFit="none"
          />
        }
      />
    </div>
  );
};

export default NotFoundCover;
